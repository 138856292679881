import { Stack } from "@mui/material";
import { SYSTEM_USER_ID, UserBasicDto, UserDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import { translationKeys } from "../../translations/main-translations";
import UserAvatar from "../user/UserAvatar";
import UserEntryWithPopup from "../user/UserEntryWithPopup";

interface ITableUserCellProps<D extends object = Record<string, unknown>> extends CellProps<D> {
    users?: UserDto[] | null;

    /**
     * User to display. If a number is passed, the user is resolved using the users prop
     *
     * @type {(UserDto | number | null | undefined)}
     * @memberof ITableUserCellProps
     */
    value: UserBasicDto | number | null | undefined;
}

const TableUserCell = <D extends object = Record<string, unknown>>({
    value,
    users,
    children,
}: React.PropsWithChildren<ITableUserCellProps<D>>) => {
    const { t: translate } = useTranslation();

    if (value == null) {
        return null;
    }

    if (value === SYSTEM_USER_ID) {
        return (
            <Stack direction="row" alignItems="center" spacing={1}>
                <UserAvatar isSystemUser size={23} />
                <span>{translate(translationKeys.VDLANG_SYSTEM_USER)}</span>
            </Stack>
        );
    }

    if (typeof value === "number" && users == null) {
        // not able to resolve the user, show the id instead
        return (
            <Stack direction="row" alignItems="center" spacing={1}>
                <span>{value}</span>
                {children}
            </Stack>
        );
    }
    const user = typeof value === "number" && users != null ? users.find((u) => u.id === value) : value;
    if (typeof user !== "object") {
        return null;
    }
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <UserEntryWithPopup inline disableGutters user={user} avatarProps={{ size: 23 }} />
            {children}
        </Stack>
    );
};

export default TableUserCell;
